import { setHtmlAttribute } from "@/utils";
import { useTheme } from "next-themes";
import FaMoon from "../SVG/Fontawesome/FaMoon";
import SunIcon from "../SVG/SunIcon";

const ThemeIcon = () => {
  const { theme, setTheme } = useTheme();

  const handleChangeTheme = (theme: string) =>{
    setTheme(theme);
    setHtmlAttribute("class", theme);
  }

  return <>
    {theme === "dark"
      ?
      <div id="dark-accent" onClick={() => handleChangeTheme("light")} className={`flex basis-1/2 justify-center items-center text-base sm:text-2xl font-medium transition-all ${(theme === "dark") ? "text-slate-600" : "text-primary"}`}>
        <FaMoon />
      </div>
      :
      <div id="light-accent" onClick={() => handleChangeTheme("dark")} className={`flex basis-1/2 justify-center items-center text-base sm:text-2xl font-medium transition-all ${(theme === "light") ? "text-primary" : "text-primary"}`}>
        <SunIcon />
      </div>
    }
  </>
}

const ThemeChange = () => {
  return (
    <div suppressHydrationWarning>
      <button
        name="theme-btn"
        id="theme-btn"
        type="button"
        className="text-primary font-bold px-2 rounded-md text-base sm:text-2xl hidden lg:block"
      >
        <ThemeIcon />
      </button>
    </div>
  )
}

export default ThemeChange;